<template>
  <div>
    <nf-breadcrumb :breadList="[{ name: '知识产权' }, { name: '专利机器人' }]"></nf-breadcrumb>
    <div class="imgWrap">
      <img src="@/assets/images/property/jqr-banner@2x.png" alt="" />
    </div>
    <div class="chatWrap">
      <div class="title">
        <span class="titleOne">在线客服</span>
        <span class="division">|</span>
        <span class="titleTwo">正在与机器人对话</span>
      </div>
      <div class="chatContent">
        <div class="chatItemWrap" v-for="(item, index) in inputArr" :key="index">
          <div :class="item.role === 1 ? 'chatItem self' : 'chatItem robot'" v-html="item.text"></div>
        </div>
      </div>
      <div class="inputWrap">
        <el-input type="textarea" @keydown.enter.native.prevent="handleSend" placeholder="请输入内容" v-model="textarea"> </el-input>
        <el-button type="primary" round @click.enter="handleSend">发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import { Message } from 'element-ui';

export default {
  name: 'patentRobot',
  data() {
    return {
      textarea: '', // 输入内容
      inputArr: [] // 聊天内容
    };
  },
  methods: {
    // 点击发送
    handleSend() {
      if (this.textarea.trim() !== '') {
        this.inputArr.push({ text: this.textarea, role: 1 });
        this.scrollToBottom();
        // 发送请求
        this.getMessage(this.textarea);
        this.textarea = '';
      }
    },
    // 请求后台数据
    async getMessage(text) {
      const api = this.$fetchApi.robotAnswer.api;
      const data = await this.$fetchData.fetchPost({ text }, api, 'json');
      if (data.code === '200' && data.result) {
        if (data.result.length !== 0) {
          let textStr = '';
          data.result.forEach((item, index) => {
            textStr += `<div onclick='toDetail("${item.id}")' style='color: #2196f3; padding-top: 10px;'>${index + 1}. ${
              item.answer
            }</div>`;
          });
          textStr = '<div>关于您问题的答案:</div>' + textStr;
          this.inputArr.push({
            text: textStr,
            role: 2
          });
        } else {
          this.inputArr.push({
            text: '暂无答案',
            role: 2
          });
        }
      } else {
        Message.error(data.message);
      }
      this.scrollToBottom();
    },
    // 入场语
    async getWelcome(type) {
      const api = this.$fetchApi.robotInit.api;
      const data = await this.$fetchData.fetchPost({ type }, api, 'json');
      if (data.code === '200' && data.success) {
        this.inputArr.push({
          text: data.message,
          role: 2
        });
      }
    },
    // 定位底部
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$el.querySelector('.chatContent');
        container.scrollTop = container.scrollHeight;
      });
    },
    // 跳转详情
    toDetail(id) {
      const routeUrl = this.$router.resolve({
        path: '/patentSearch/patentDetail',
        query: { id }
      });
      window.open(routeUrl.href, '_blank');
    }
  },
  components: { nfBreadcrumb },
  async created() {
    window.toDetail = this.toDetail;
    this.getWelcome(1);
  }
};
</script>

<style lang="less" scoped>
.imgWrap {
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
}
.chatWrap {
  width: 800px;
  margin: 10px auto;
  height: 620px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ccc;
  .title {
    height: 80px;
    border-bottom: 1px solid #ccc;
    padding-top: 40px;
    padding-left: 50px;
    box-sizing: border-box;
    .titleOne {
      font-weight: 600;
      font-size: 25px;
    }
    .titleTwo {
      font-size: 16px;
    }
    .division {
      padding: 0 20px;
    }
  }
  .chatContent {
    height: 400px;
    padding: 20px;
    overflow: auto;
    box-sizing: border-box;
    background: #fafafa;
    .chatItemWrap {
      overflow: hidden;
      margin-bottom: 20px;
      .chatItem {
        font-size: 20px;
        border-radius: 10px;
        border: 1px solid #ccc;
        padding: 10px 20px;
        background: #fff;
        max-width: 300px;
        word-wrap: break-word;
        word-break: break-all;
        color: #333;
      }
      .chatItem.self {
        float: right;
      }
      .chatItem.robot {
        float: left;
      }
    }
  }
  .inputWrap {
    height: 140px;
    position: relative;
    .el-button {
      position: absolute;
      right: 15px;
      bottom: 10px;
    }
    /deep/ .el-textarea__inner {
      padding: 10px 20px;
      font-size: 18px;
      resize: none;
      height: 140px;
    }
  }
}
.breadWrap {
  border-bottom: 1px solid #ccc;
  .el-breadcrumb {
    width: 1200px;
    margin: 20px auto;
    font-size: 16px;
  }
}
</style>
